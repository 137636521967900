import type { FetchPersonQueryVariables } from '#gql/default'

export default async function usePerson({
  variables,
  throwError,
}: {
  variables: FetchPersonQueryVariables
  throwError?: boolean
}) {
  const { data, status } = await useAsyncGql('FetchPerson', variables)
  const pending = computed(() => status.value === 'pending')

  if (throwError && !data.value?.person) {
    showError({
      statusCode: 404,
      statusMessage: 'personNotFound',
    })
  }

  const person = computed(() => data.value?.person)

  return {
    person,
    pending,
  }
}
