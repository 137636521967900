<script lang="ts" setup>
import { useRouteParams } from '@vueuse/router'

defineI18nRoute({
  paths: {
    en: '/person/[personSlug]',
    de: '/person/[personSlug]',
    es: '/persona/[personSlug]',
  },
})

defineOptions({
  name: 'PagePersonSlug',
})

const urlSlug = useRouteParams('personSlug', '', { transform: String })
const { page } = useCms()

const { person, pending } = await usePerson({
  variables: {
    urlSlug: urlSlug.value,
  },
  throwError: true,
})

</script>

<template>
  <div class="contents">
    <CmsPage v-if="page" :page />
    <GenericPagePerson v-else-if="!pending && person" :person />
  </div>
</template>

